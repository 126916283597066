.header {
  position: fixed;
  top: 0;
  width: 100%;
  padding: 15px 0;
  background: var(--black);
  z-index: 20;

  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__logo img {
    width: 100%;
    height: 100%;
  }

  &__desktop {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .hamburger {
    display: none;
    flex-direction: column;
    justify-content: space-between;
    min-width: 23px;
    height: 19px;
    cursor: pointer;
    position: relative;

    .line {
      height: 3px;
      width: 100%;
      border-radius: 10px;
      background: var(--white);
      transition: transform 0.4s ease-in-out, opacity 0.2s ease-in-out;
    }

    .line1 {
      transform-origin: 0 0;

      &.active {
        background-color: var(--green);
        transform: rotate(45deg);
      }
    }

    .line2 {
      transition: opacity 0.2s ease-in-out;

      &.active {
        opacity: 0;
      }
    }

    .line3 {
      transform-origin: 0 100%;

      &.active {
        background-color: var(--green);
        transform: rotate(-45deg);
      }
    }
  }

  .menu {
    position: fixed;
    display: none;
    top: 70px;
    width: 100%;
    height: 100vh;
    background-color: var(--black);
    transform: translateX(150%);
    transition: transform 0.5s ease-in-out;

    &.active {
      transform: translateX(0);
    }

    .header__mobile {
      display: none;
    }
  }
}

@media (max-width: 1130px) {
  .header {
    .menu {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 24px 40px;

      .header__mobile {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
      }
    }

    .hamburger {
      display: flex;
      min-width: 24px;
      height: 19px;
    }
  }
}

@media (max-width: 768px) {
  .header {
    padding: 10px 0;

    &__logo {
      width: 68px;
      height: 35px;
    }

    .menu {
      height: 93%;
      top: 63px;
    }
  }
}