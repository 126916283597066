@import "fonts";
@import "variables";
@import "libs/normalize";


body {
  position: relative;
  background-color: var(--main-bg);
  color: var(--white);
}

.container {
  width: 100%;
  max-width: var(--container-width);
  padding-inline: 44px;
  margin-inline: auto;
}

.title {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: 48px;
  text-align: center;
  color: var(--white);
  margin-bottom: 40px;
  line-height: 1;

  &:first-letter {
    text-transform: uppercase;
  }
}

@media only screen and (max-width: 1280px) {
  .container {
    padding-inline: 40px;
  }
}

@media only screen and (max-width: 960px) {
  .container {
    padding-inline: 26px;
  }
  .title {
    margin-bottom: 24px;
  }
}

@media only screen and (max-width: 768px) {
  .title {
    font-size: 40px;
  }
}

@media only screen and (max-width: 480px) {
  .container {
    padding-inline: 20px;
  }
  .title {
    font-size: 32px;
  }
}

::-webkit-scrollbar {
  width: 0;
  display: none;
  opacity: 0;
}

:root {
  --bg: #3e3e3e;
  --black: #202020;
  --main-bg: #151515;
  --green: #00f222;
  --txt: #c3c3c3;
  --white: #fff;
  --container-width: 1268px;
}