.socials {
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  gap: 28px;

  a {
    display: block;
    transition: transform 0.2s;

    &:hover {
      transform: scale(1.1);
    }

    img {
      min-width: 40px;
      width: 40px;
      aspect-ratio: 1;
    }
  }
}

@media only screen and (max-width: 728px) {
  .socials {
    gap: 20px;

    a {
      img {
        min-width: 28px;
        width: 28px;
      }
    }
  }
}