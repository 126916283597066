.ourTeam {
  margin-block: 120px;
  color: #fff;
  overflow: hidden;

  @media only screen and (max-width: 960px) {
    margin-block: 64px;
  }

  @media only screen and (max-width: 480px) {
    margin-block: 48px;
  }

  @media only screen and (max-width: 360px) {
    margin-block: 32px;
  }
}

.titlePagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;

  .title {
    font-size: 48px;
    text-align: center;
    color: #fff;
    font-weight: 600;
    &:first-letter {
      text-transform: uppercase;
    }
  }
}

.swiperWrapper {
  position: relative;
  padding: 0 40px;
  margin-bottom: 30px;
}

.swiperContainer {
  width: 100%;
  overflow: visible;
}

.teamCard {
  position: relative;
  height: 460px;
  width: 100%;
  max-width: 100%;
  border-radius: 12px;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  &:hover {
    transform: translateY(-10px);

    .memberInfo {
      transform: translateY(0);
      opacity: 1;
    }
  }

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    background: linear-gradient(180.05deg, rgba(0, 0, 0, 0) 24.39%, rgba(0, 0, 0, 0.81) 94.74%);
    z-index: 1;
  }
}

.memberInfo {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 4px;
  padding: 20px 10px;
  z-index: 2;
  backdrop-filter: blur(4px);
  background: rgba(32, 32, 32, 0.8);
  transform: translateY(100%);
  opacity: 0;
  transition: transform 0.3s ease, opacity 0.3s ease;
  width: 100%;
}

.memberName {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: 20px;
  text-align: center;
  color: var(--white);
  line-height: 1;
}

.memberPosition {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  color: #afafaf;
}

.navigationWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;

  .arrowLeft,
  .arrowRight {
    width: 48px;
    aspect-ratio: 1;
    background: var(--white);
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 10;
    transition: all 0.3s ease;
  }
}



@media (max-width: 960px) {
  .titlePagination {
    margin-bottom: 24px;
  }
}

@media (max-width: 768px) {
  .titlePagination {
    .title {
      font-size: 30px;
    }
  }

  .memberName {
    font-size: 18px;
  }

  .memberPosition {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .titlePagination {
    .title {
      font-size: 32px;
    }
  }

  .swiperWrapper {
    padding: 0 30px;
  }

  .teamCard {
    height: 400px;
  }

  .navigationWrapper {

    .arrowLeft,
    .arrowRight {
      width: 32px;
    }
  }
}