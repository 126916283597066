.aboutUs {
  margin-block: 120px;
  padding-block: 80px;
  background: var(--black);

  &__inner {
    display: flex;
    flex-direction: column;
  }

  &__text {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 16px;
    color: var(--txt);
    margin-bottom: 24px;
  }

  &__contact {
    border-radius: 8px;
    padding: 12px 20px;
    width: max-content;
    margin-bottom: 40px;
    height: 49px;
    background: var(--green);
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    color: var(--black);
    text-wrap: nowrap;
    cursor: pointer;

    &:first-letter {
      text-transform: uppercase;
    }
  }

  &__imgs {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 16px;
  }

  &__imgWrapper {
    border-radius: 8px;
    overflow: hidden;
    height: clamp(100px, calc(100px + (410 - 100) * ((100vw - 360px) / (1228 - 360))), 410px);

    &:nth-child(4n+1) {
      grid-column: span 7;
    }

    &:nth-child(4n+2) {
      grid-column: span 5;
    }

    &:nth-child(4n+3) {
      grid-column: span 5;
    }

    &:nth-child(4n) {
      grid-column: span 7;
    }
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media only screen and (max-width: 960px) {
    margin-block: 64px;

    &__imgs {
      gap: 12px;
    }
  }

  @media only screen and (max-width: 480px) {
    margin-block: 48px;

    &__imgs {
      gap: 8px;
    }
  }

  @media only screen and (max-width: 360px) {
    margin-block: 32px;
  }
}