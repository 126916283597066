.footer {
  width: 100%;
  min-height: 200px;
  display: flex;
  align-items: center;
  background: var(--black);

  &__inner {
    display: flex;
    justify-content: space-between;
    gap: 60px;

    .contacts {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;

      .contact {
        .contactItem {
          display: flex;
          align-items: center;
          gap: 24px;

          a {
            text-align: start;
            font-family: var(--font-family);
            font-weight: 400;
            font-size: 20px;
            color: #e1e1e1;
          }

          .contactIcon {
            width: 32px;
            height: 32px;
          }
        }
      }
    }
  }

  &__text {
    display: flex;

    .footer__logo {
      width: 140px;

      >img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

@media only screen and (max-width: 960px) {
  .footer {
    &__inner {
      .contacts {
        .contact {
          .contactItem {
            a {
              font-size: 18px;
            }
          }
        }
      }}
  }
}



@media only screen and (max-width: 728px) {
  .footer {
    text-align: center;

    &__inner {
      .contacts {
        .contact {
          .contactItem {
            a {
              font-size: 18px;
            }
          }
        }
      }

      .footer__text {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: flex-start;
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .footer {
    padding: 24px 20px;

    &__inner {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;

      .contacts {
        .contact {
          .contactItem {
            a {
              font-size: 15px;
            }
          }
        }
      }
    }
  }
}