@import "../../scss/variables";

.comments {
  margin-block: 120px;
  color: #fff;
  overflow: hidden;

  @media only screen and (max-width: 960px) {
    margin-block: 64px;
  }

  @media only screen and (max-width: 480px) {
    margin-block: 48px;
  }

  @media only screen and (max-width: 360px) {
    margin-block: 32px;
  }
}

.titlePagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;

  .title {
    font-size: 48px;
    text-align: center;
    color: #fff;
    font-weight: 600;

    &:first-letter {
      text-transform: uppercase;
    }
  }
}

.navigationWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;

  .arrowLeft,
  .arrowRight {
    width: 48px;
    aspect-ratio: 1;
    background: var(--white);
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 10;
    transition: all 0.3s ease;
  }
}

.swiperContainer {
  div[class~=swiper-slide] {
    height: auto;
    display: flex;
  }
}

.commentCard {
  border-radius: 12px;
  padding: 32px 24px;
  background: var(--black);
  display: flex;
  flex-direction: column;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  height: 100%;
  min-height: 100%;

  .commentInfo {
    display: flex;
    align-items: flex-start;
    margin-bottom: 12px;

    .avatar {
      width: 64px;
      min-width: 64px;
      height: 64px;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 12px;
      border: 1px solid var(--white);

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .info {
      display: flex;
      flex-direction: column;

      .name {
        font-family: var(--font-family);
        line-height: 1;
        font-weight: 600;
        font-size: 20px;
        color: var(--white);
        margin-bottom: 4px;
      }

      .position {
        font-family: var(--font-family);
        font-weight: 400;
        font-size: 14px;
        color: rgba(255, 255, 255, 0.55);
      }
    }
  }

  .description {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 16px;
    color: var(--txt);
    flex-grow: 1;
  }
}