.header {
  &__info {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 50px;

    .navKg {
      display: flex;
      flex-direction: column;
      gap: 11px;
      font-size: 16px;
    }

    .navNone {
      navKg {
        display: flex;
        flex-direction: column;
      }
    }
  }

  &__contact {

    border-radius: 8px;
    padding: 12px 20px;
    width: max-content;
    height: 49px;
    background: var(--green);
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    color: var(--black);
    text-wrap: nowrap;
    cursor: pointer;

    &:first-letter {
      text-transform: uppercase;
    }
  }
}

.nav .active {
  font-weight: 700;
  color: var(--white);
}

.nav {
  display: flex;
  align-items: flex-start;
  column-gap: 16px;
  font-size: 16.5px;

  &__link {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 18px;
    color: var(--white);
    cursor: pointer;
    padding: 5px 10px;
    transition: all 0.15s ease-in;
    &.isHamburger {
      padding: 20px 0px 0px 0px;
      width: 100%;
      height: 55px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.4);
    }

    &_en {
      gap: 120px;
    }

    &_kg {
      font-size: 15px;
    }

    &:first-letter {
      text-transform: uppercase;
    }

    &:hover {
      color: var(--green);
    }
  }
}

.translate {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  gap: 8px;
  background: var(--black);
  border-radius: 8px;
  padding: 8px 10px;
  width: 84px;
  height: 49px;
  color: var(--white);
  position: relative;
  cursor: pointer;
  transition: all 0.15s ease-in;
  margin-right: 20px;

  &:hover,
  &.active {
    background: var(--main-bg);
    box-shadow: 4px 4px 4px 0 rgba(0, 0, 0, 0.25);
  }

  &__box {
    background-color: var(--main-bg);
    border-radius: 8px;
    border: 0;
    color: var(--white);

    .select-options {
      background-color: var(--main-bg);
    }
  }

  &__popup {
    background: var(--main-bg);
    position: absolute;
    top: 64px;
    right: 0;
    border-radius: 8px;
    z-index: 1;
    box-shadow: 4px 4px 4px 0 rgba(0, 0, 0, 0.25);
  }

  &__item {
    border-radius: 8px 8px 0 0;
    padding: 10px 8px;
    width: 140px;
    height: 42px;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
    color: var(--white);

    &:hover {
      color: var(--green);
    }
  }
}

@media only screen and (min-width: 1200px) {
  .header {
    .nav {
      font-size: 18px;
      gap: 20px;
    }
  }
}

@media only screen and (max-width: 1130px) {
  .header {
    &__info {
      &.isHamburger {
        width: 100%;
        justify-content: flex-start;
      }
    }
  }

  .nav {
    &.isHamburger {
      flex-direction: column;
      width: 100%;
    }

    &.isMobile {
      display: none;
    }
  }

  .translate {
    &.isHamburger {
      display: none;
    }
  }

  .header__contact {
    &.isHamburger {
      display: none;
    }
    &.isMobile {
      margin-top: 24px;
    }
  }
}