* {
  padding: 0;
  margin: 0;
  list-style: none;
  outline: none;
  font-family: 'Nunito', sans-serif;;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

button{
  border: none;
  cursor: pointer;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

a,
// span,
p,
b,
h1,
h2,
h3,
h4,
h5 {
  color: var(--white);
  margin: 0;
}

h1 {
  font-weight: 700;
  font-size: 56px;
  line-height: 72px;
}

h2 {
  font-weight: 700;
  font-size: 56px;
  line-height: 72px;
}

h3 {
  font-weight: 500;
  font-size: 32px;
  line-height: 38px;
}


a {
  text-decoration: none;
  color: inherit;
}

image{
  width: 100%;
  height: 100%;
}

