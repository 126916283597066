.swiperSwiper {
  margin-block: 120px;
  width: 100%;
  border: 1px solid var(--main-bg);
  min-height: 380px;
  display: flex;
  flex-direction: column;

  .swiper {
    display: flex;
    flex-direction: column;

    @media (max-width: 960px) {
      flex-direction: column-reverse;
    }
  }

  @media only screen and (max-width: 960px) {
    margin-block: 64px;
  }

  @media only screen and (max-width: 480px) {
    margin-block: 48px;
  }

  @media only screen and (max-width: 360px) {
    margin-block: 32px;
  }
}

.infoBlock {
  padding: 48px 24px;
  background: var(--black);
}

.infoWrapper {
  display: flex;
  flex-direction: row;
  gap: 24px;

  @media (max-width: 728px) {
    flex-direction: column;
    align-items: center;
  }
}

.imageContainer {
  width: 50%;
  height: 256px;
  min-width: 120px;


  .companyImage {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
    border-radius: 8px;
  }
}

.contentContainer {
  width: 100%;

  .btns {
    display: flex;
    gap: 16px;
    flex-wrap: wrap;

    .btn {
      border-radius: 8px;
      padding-inline: 20px;
      width: max-content;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid var(--white);
      background-color: transparent;
      text-wrap: nowrap;
    }

    .watchProject {
      border: 1px solid var(--green);
    }

    .btnApps {
      display: flex;
      gap: 16px;
      @media (max-width: 400px) {
        flex-direction: column;
      }
    }
  }
}

.companyTitle {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: 30px;
  color: var(--white);
  margin-bottom: 24px;
  line-height: 1;
}

.companyDescription {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 16px;
  color: var(--txt);
  margin-bottom: 60px;
  line-height: 1.3;

  @media (max-width: 960px) {
    margin-bottom: 24px;
  }
}

.carouselContainer {
  border-top: 1px solid transparent;
  padding-top: 1px;
}

.logoItem {
  background: var(--black);
  border-bottom: 1px solid var(--main-bg);
  border-right: 1px solid var(--main-bg);
  padding: 24px 12px;
  height: 120px;

  @media (max-width: 960px) {
    flex-direction: column-reverse;
  }

  cursor: pointer;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-2px);
  }
}

.logoActive {
  border-bottom-color: #3b82f6;
}

.logoImage {
  height: 90px;
  object-fit: contain;
  opacity: 0.7;
  transition: opacity 0.3s ease;

  .logoItem:hover & {
    opacity: 1;
  }

  .logoActive & {
    opacity: 1;
  }
}

/* Стили для Swiper */
.swiperContainer {
  width: 100%;
}

/* Переопределение стилей Swiper */
:global {

  .swiper-button-next,
  .swiper-button-prev {
    color: #3b82f6;

    &:after {
      font-size: 20px;
    }
  }

  .swiper-button-disabled {
    opacity: 0.35;
  }
}