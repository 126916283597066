.tech_scroll_container {
  width: 100%;
  display: flex;
  align-items: flex-end;
  padding-top: 5px;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
}

.tech_grid {
  display: flex;
  flex-wrap: nowrap;
  gap: 15px;
  min-width: min-content;
}

.stack_tech {
  margin-block: 120px;
  border-radius: 24px;
  padding: 80px 40px;
  background: var(--black);

  .stack_title {
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 16px;
    text-align: center;
  }

  .stack_description {
    max-width: 800px;
    margin: 0 auto 40px;
    text-align: center;
    font-size: 18px;
    color: rgba(255, 255, 255, 0.7);
    line-height: 1.5;
  }

  .categories_wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .categories_row {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding-inline: 0;
    gap: 40px;
    margin-bottom: 40px;
    max-width: calc(200px * 4 + 40px * 3);
  }

  .category_section {
    min-width: 200px;
    max-width: 200px;

    .category_title {
      font-size: 24px;
      font-weight: 500;
      margin-bottom: 20px;
    }
  }

  .tech_grid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    max-width: 200px;
    min-width: min-content;
  }

  .tech_item {
    min-width: 90px;
    max-width: 90px;
    background: #3c3c3c;
    border-radius: 8px;
    padding: 12px 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: transform 0.2s ease;
    flex: 0 0 auto;

    &:hover {
      transform: translateY(-5px);
    }

    .tech_icon {
      width: 48px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 8px;

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }

    .tech_name {
      font-size: 14px;
      font-weight: 400;
      text-align: center;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .stack_tech {
    flex-wrap: nowrap;

    .stack_description {
      margin: 0 auto 20px;
    }

    .categories_wrapper {
      justify-content: flex-start;
    }

    .categories_row {
      flex-direction: column;
      gap: 20px;
      overflow: scroll;
    }

    .category_section {
      min-width: 200px;
      max-width: 100%;
    }

    .tech_grid {
      display: flex;
      flex-wrap: nowrap;
      gap: 20px;
      max-width: 100%;
    }
  }
}


@media only screen and (max-width: 960px) {
  .stack_tech {
    padding: 32px 24px;
  }

  .stack_tech {
    .categories_row {
      padding-inline: 24px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .stack_tech {
    margin-block: 80px;

    .stack_title {
      font-size: 30px;
    }

    .stack_description {
      font-size: 16px;
    }

    .category_section {
      min-width: 100%;

      .category_title {
        font-size: 20px;
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .stack_tech {
    margin-block: 48px;

    .categories_row {
      gap: 16px;
    }
  }
}

@media only screen and (max-width: 360px) {
  .stack_tech {
    padding: 24px 16px;
  }
}