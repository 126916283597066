.dev_stages {
  margin-block: 120px;

  .stages_box {
    overflow: hidden;
    width: 100%;
    display: flex;
    justify-content: space-between;
    justify-content: flex-end;
    gap: clamp(24px, 5vw, 100px);

    .stages__items {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;
      width: 100%;
    }

    .develops {
      width: 100%;
      max-width: 500px;
      aspect-ratio: 650/500;
      border-radius: 8px;

      img {
        width: 100%;
        border-radius: 8px;
      }
    }
  }

  .line {
    position: absolute;
    top: 28px;
    left: 16px;
    background-color: var(--green);
  }


  .circle {
    position: absolute;
    width: 32px;
    height: 32px;
    top: 0;
    left: 0;
  }

  .stage {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-left: clamp(40px, 5vw, 100px);


    &__title {
      font-family: var(--font-family);
      font-weight: 600;
      font-size: 30px;
      color: var(--white);
      margin-bottom: 16px;
    }

    &__subtitle {
      font-family: var(--font-family);
      font-weight: 400;
      font-size: 16px;
      color: var(--txt);
    }
  }
}

@media only screen and (max-width: 960px) {
  .dev_stages {
    margin-block: 64px;

    .stages_box {
      gap: 24px;

      .stages__items {
        gap: 20px;
      }

      .stage__title {
        margin-bottom: 12px;
      }
    }

    .stage {
      padding: 0;
    }
  }

  .circle,
  .line {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .dev_stages {
    .stages_box {
      flex-direction: column;

      .stage__title {
        font-size: 24px;
      }

      .develops {
        max-width: 100%;
        aspect-ratio: auto;
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .dev_stages {
    margin-block: 48px;

    .stages_box {
      .stage__title {
        font-size: 20px;
      }
    }
  }

  .dev_stages {
    .stages_box {
      .stage__subtitle {
        font-size: 15px;
      }
    }
  }
}