.our_service {
  margin-block: 120px;

  .service_box {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 20px;
  }
}

@media only screen and (max-width: 1024px) {
  .our_service {
    margin-block: 120px;

    .service_box {
      margin-top: 40px;
    }
  }
}

@media only screen and (max-width: 960px) {
  .our_service {
    margin-block: 64px;

    .service_box {
      margin-top: 40px;
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }
}

@media only screen and (max-width: 768px) {
  .our_service {
    .service_box {
      margin-top: 24px;
    }
  }
}

@media only screen and (max-width: 480px) {
  .our_service {
    margin-block: 48px;
  }
}