.service_card {
  border-radius: 8px;
  padding: 32px 24px;
  height: 266px;
  background: var(--black);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transition: all 0.15s ease-in;

  &:hover {
    transform: scale(1.05);
  }

  &__img {
    max-width: 63px;
    min-width: 63px;
    aspect-ratio: 1;
    border: 0.73px solid var(--green);
    border-radius: 100%;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;

    img {
      width: 80%;
      aspect-ratio: 1;
      object-fit: cover;
    }
  }

  &__title {
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 24px;
    line-height: 1.3;
    color: var(--white);
    margin-bottom: 8px;

    &:first-letter {
      text-transform: uppercase;
    }
  }

  &__subtitle {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 16px;
    line-height: 1.3;
    color: var(--txt);

    &:first-letter {
      text-transform: uppercase;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .service_card {
    height: auto;

    &:hover {
      transform: scale(1);
    }
  }
}

@media only screen and (max-width: 960px) {
  .service_card {
    height: auto;
    padding: 32px 16px;

    &:hover {
      transform: scale(1);
    }
  }
}

@media only screen and (max-width: 768px) {
  .service_card {
    padding: 24px 16px;

    &__title {
      font-size: 18px;
    }

    &__subtitle {
      font-size: 16px;
    }
  }
}

@media only screen and (max-width: 480px) {
  .service_card {
    padding: 16px 8px;

    &__img {
      max-width: 48px;
      min-width: 48px;
    }

    &__title {
      font-size: 17px;
    }

    &__subtitle {
      font-size: 15px;
    }
  }
}